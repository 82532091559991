import Types from "./types";
import { updateSSRAppliedFilters, copyAppliedToSelectedFilters } from "../filter-v2/action";
import { CatalogGatewayService } from "../../../service/catalog-gateway";
import { CarService } from "../../../service/car-service";
import { UsedCarService } from "../../../service/used-car-service";
import { CmsServiceApi } from "../../../service/cms-service";

const getFeaturedPopularCities = (cities) => {
    return (cities && cities.length) ? cities.filter((make) => make.featured) : [];
};

const fetchCarListInit = () => ({
    type: Types.FETCH_CAR_LIST
});

const fetchCarListSuccess = (data) => ({
    type: Types.FETCH_CAR_LIST_SUCCESS,
    data
});

const fetchCarListFailure = (error, redirectUrl) => ({ type: Types.FETCH_CAR_LIST_FAILURE, error, redirectUrl });

const fetchCarList = (data) => (dispatch) => {
    dispatch(fetchCarListInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCarlist(data)
            .then(response => {
                if (response.data.redirectUrl) {
                    dispatch(fetchCarListFailure(null, response.data.redirectUrl));
                } else {
                    dispatch(fetchCarListSuccess(response.data));
                    if (response.data.appliedFilter) {
                        dispatch(updateSSRAppliedFilters(response.data.appliedFilter));
                        dispatch(copyAppliedToSelectedFilters());
                    }
                    resolve(response.data);
                }
            }).catch(error => {
                dispatch(fetchCarListFailure(error));
                reject(error);
            });
    });
};

const fetchOtherHubsInCityInit = () => ({
    type: Types.FETCH_OTHER_HUBS_IN_CITY
});

const fetchOtherHubsInCitySuccess = (data) => ({
    type: Types.FETCH_OTHER_HUBS_IN_CITY_SUCCESS,
    data
});

const fetchOtherHubsInCityFailure = (error) => ({ type: Types.FETCH_OTHER_HUBS_IN_CITY_FAILURE, error });

const fetchOtherHubsInCity = (cityId) => dispatch => {
    dispatch(fetchOtherHubsInCityInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.fetchOtherHubsInCity(cityId)
            .then(response => {
                dispatch(fetchOtherHubsInCitySuccess(response.data));
                resolve(response.data.data);
            }).catch(error => {
                dispatch(fetchOtherHubsInCityFailure(error));
                reject(error);
            });
    });
};

const updateSortOrder = (sort) => ({
    type: Types.UPDATE_SORT_ORDER,
    sort
});

const clearUnappliedFiltersAndSortOrder = () => ({
    type: Types.CLEAR_UNAPPLIED_FILTERS_AND_SORT_ORDER
});

const applyFilterAndSortOrder = () => ({
    type: Types.APPLY_FILTERS_AND_SORT_ORDER
});

const resetCarList = () => ({
    type: Types.RESET_CAR_LIST
});

const setActiveScreen = (activeScreen) => ({
    type: Types.SET_ACTIVE_SCREEN,
    activeScreen
});

const setActiveTab = (activeTab) => ({
    type: Types.SET_ACTIVE_TAB,
    activeTab
});

const setDisplayType = (displayType) => ({
    type: Types.SET_DISPLAY_TYPE,
    displayType
});

const setDefaultDynamicFilterSettings = (data) => ({
    type: Types.SET_DEFAULT_DYNAMIC_FILTER_SETTINGS,
    data
});

const updateCollapsibleState = (key) => ({
    type: Types.UPDATE_COLLAPSIBLE_STATES,
    key
});

const updateAppliedFilters = (entities) => ({
    type: Types.UPDATE_APPLIED_FILTERS,
    ...entities
});

const copyAppliedToUnappliedFilters = () => ({ type: Types.COPY_UNAPPLIED_FILTERS });

const applySelectedFilters = () => ({ type: Types.APPLY_SELECTED_FILTERS });

const updateCurrentPage = (page) => ({
    type: Types.UPDATE_CURRENT_PAGE,
    page
});

const updateCurrentPageWithTimestamp = (currentPage) => ({
    type: Types.UPDATE_CURRENT_PAGE_WITH_TIMESTAMP,
    currentPage
});

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const fetchCarCountsInit = () => ({
    type: Types.FETCH_CAR_COUNTS
});

const fetchCarCountsSuccess = (unappliedCarCount) => ({
    type: Types.FETCH_CAR_COUNTS_SUCCESS,
    unappliedCarCount
});

const resetUnappliedCarCount = () => ({
    type: Types.RESET_UNAPPLIED_CAR_COUNT
});

const fetchCarCountsFailure = () => ({ type: Types.FETCH_CAR_COUNTS_FAILURE });

const fetchCarCounts = (params, allInventory = false) => (dispatch) => {
    dispatch(fetchCarCountsInit());
    return new Promise((resolve, reject) => {
        UsedCarService.fetchCarCounts(params, allInventory)
            .then(response => {
                dispatch(fetchCarCountsSuccess(response.data.data.count));
                resolve(response);
            }).catch((error) => {
                dispatch(fetchCarCountsFailure());
                reject(error);
            });
    });
};

const updateFilterTag = (data) => {
    return {
        type: Types.UPDATE_FILTER_TAG,
        ...data
    };
};

const overrideSpecifiedAppliedFilter = (entity) => ({
    type: Types.OVERRIDE_SPECIFIED_APPLIED_FILTER,
    ...entity
});

const updateIsRedirected = (isRedirected) => ({
    type: Types.UPDATE_IS_REDIRECTED,
    isRedirected
});

const addAndApplyFilter = (data) => ({
    type: Types.ADD_AND_APPLY_FILTER,
    ...data
});

const updateAppliedFilterTimestamp = (lastUpdatedAppliedFilters = null) => ({
    type: Types.UPDATE_APPLIED_FILTER_TIMESTAMP,
    lastUpdatedAppliedFilters
});

const clearAllFilters = () => ({
    type: Types.CLEAR_ALL_FILTERS
});

const updateIsFilterSettingsFetched = (isFilterSettingsFetched) => ({
    type: Types.UPDATE_IS_FILTER_SETTINGS_FETCHED,
    isFilterSettingsFetched
});

// CHANGE_LIST_NAME has to be removed - will remove after c2c-3538 goes live
const changeListName = (listName) => ({
    type: Types.CHANGE_LIST_NAME,
    listName
});

const updateCarListOptions = (options, removeOptions) => ({
    type: Types.UPDATE_CAR_LIST_OPTIONS,
    options,
    removeOptions
});

const updateUnAppliedFilters = (entities) => ({
    type: Types.UPDATE_UNAPPLIED_FILTERS,
    ...entities
});

const getOffScreenFilterCount = () => ({
    type: Types.OFF_SCREEN_FILTER_COUNT
});

const fetchgetBannerCarCountSuccess = (data) => ({
    type: Types.FETCH_GA_BANNER_COUNT_SUCCESS,
    ...data
});

const getBannerCarCount = (pinId, allInventory = false) => (dispatch) => {
    return new Promise((resolve, reject) => {
        UsedCarService.fetchBannerCarCounts(pinId, allInventory)
            .then(res => {
                resolve(res.data.data);
                dispatch(fetchgetBannerCarCountSuccess(res.data.data));
            }).catch(error => {
                reject(error);
                dispatch(fetchgetBannerCarCountSuccess(0));
            });
    });
};

const setComingSoon = (isComingSoon) => ({
    type: Types.COMING_SOON_POPUP,
    isComingSoon
});

const updateBuyerTestimonialSSRStatus = (isBuyerTestimonialSSR) => ({
    type: Types.UPDATE_BUYER_TESTIMONIAL_SSR_STATUS,
    isBuyerTestimonialSSR
});

const fetchGetBuyerTestimonial = ({ detail }) => ({
    type: Types.SET_BUYER_TESTIMONIAL,
    detail
});

const fetchGetBuyerTestimonialFailure = () => ({
    type: Types.SET_BUYER_TESTIMONIAL_FAILURE
});

const getBuyerTestimonial = ({ makeId, modelId, cityId }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CarService.buyerTestimonial({makeId, modelId, cityId})
            .then(res => {
                resolve(res.data);
                dispatch(fetchGetBuyerTestimonial(res.data));
            }).catch(error => {
                reject(error);
                dispatch(fetchGetBuyerTestimonialFailure());
            });
    });
};

const postUserDownloadLink = (mobile) => () => {
    return new Promise((resolve, reject) => {
        UsedCarService.postUserDownloadLinkUrl(mobile)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

const setCarListingError = (error) => ({
    type: Types.CAR_LISTING_ERROR,
    error
});

const fetchNearestCities = (nearestCities) => ({
    type: Types.NEAREST_CITIES,
    nearestCities
});

const getNearestCities = (url) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getNearestcities(url)
            .then(res => {
                resolve(res.data);
                dispatch(fetchNearestCities(res.data));
            }).catch(error => {
                reject(error);
                dispatch(fetchNearestCities(null));
            });
    });
};
const updateOtherCitySSR = (isSSROtherCity) => ({
    type: Types.UPDATE_OTHER_CITY_FILTER_SSR,
    isSSROtherCity
});

const fetchSimialCarSuccess  = (similarCars, updateSimilarCarContent) => ({
    type: Types.FETCH_SIMILAR_CAR_SUCCESS,
    similarCars,
    updateSimilarCarContent
});
const fetchSimialCaFailure = (error) => ({
    type: Types.FETCH_SIMILAR_CAR_FAILURE,
    error
});

const getSimilarCarListing = (payload, updateSimilarCarContent = false) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCarlist(payload)
            .then(response => {
                resolve(response);
                dispatch(fetchSimialCarSuccess(response.data, updateSimilarCarContent));
            }).catch(error => {
                reject(error);
                dispatch(fetchSimialCaFailure(error));
            });
    });
};

const fetchOtherYardCarsListInit = () => ({
    type: Types.FETCH_OTHER_YARD_CARS_LIST
});

const fetchOtherYardCarsListSuccess = (data) => ({
    type: Types.FETCH_OTHER_YARD_CARS_LIST_SUCCESS,
    data
});

const fetchOtherYardCarsListFailure = (error) => ({ type: Types.FETCH_OTHER_YARD_CARS_LIST_FAILURE, error });

const fetchOtherYardCarsList = (data) => (dispatch) => {
    dispatch(fetchOtherYardCarsListInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCarlist(data)
            .then(response => {
                dispatch(fetchOtherYardCarsListSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchOtherYardCarsListFailure(error));
                reject(error);
            });
    });
};

const fetchCityListAndBrandsInit = () => ({
    type: Types.FETCH_POPULAR_CITY_LIST_AND_BRANDS
});

const fetchCityListAndBrandsSuccess = (data) => ({
    type: Types.FETCH_POPULAR_CITY_LIST_AND_BRANDS_SUCCESS,
    data
});

const fetchCityListAndBrandsFailure = (error) => ({ type: Types.FETCH_POPULAR_CITY_LIST_AND_BRANDS_FAILURE, error });

const fetchCityListAndBrands = () => (dispatch) => {
    dispatch(fetchCityListAndBrandsInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getPopularCityListAndBrands()
            .then(response => {
                dispatch(fetchCityListAndBrandsSuccess(getFeaturedPopularCities(response.data)));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCityListAndBrandsFailure(error));
                reject(error);
            });
    });
};

const updatePopularCityListSSRStatus = (isSSR) => ({
    type: Types.UPDATE_POPULAR_CITY_LIST_SSR_STATUS,
    isSSR
});

const reloadCarList = (shouldReloadList) => ({
    type: Types.RELOAD_CAR_LIST,
    shouldReloadList
});

const getSearchSuggestions = (text = "") => () => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getSearchSuggestions(text)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};
const updateSimilarCarSSR = (isSSRSimilarCar) => ({
    type: Types.UPDATE_SIMILAR_CAR_FILTER_SSR,
    isSSRSimilarCar
});

const updateCarListForNonServiceable = (data) => ({
    type: Types.UPDATE_CAR_LIST_FOR_NON_SERVICEABLE,
    data
});

const updateCmsData = (cmsData) => ({
    type: Types.UPDATE_CMS_DATA,
    cmsData
});

const fetchListingSeoData = (data) => () => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCarListingSeoContent(data)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};
const fetchCmsSeoData = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CmsServiceApi.getCmsData(data)
            .then(response => {
                dispatch(updateCmsData(response.data.data));
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const setShowManualPincodePopup = (data) => ({
    type: Types.SHOW_MANUAL_PINCODE_POPUP,
    data
});

const fetchTaxChargesData = (appId) => () => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getTaxChargesData(appId).then((resp) => {
            resolve(resp.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

export {
    fetchCarList,
    updateSortOrder,
    clearUnappliedFiltersAndSortOrder,
    applyFilterAndSortOrder,
    resetCarList,
    setActiveScreen,
    setActiveTab,
    setDisplayType,
    setDefaultDynamicFilterSettings,
    updateCollapsibleState,
    updateAppliedFilters,
    copyAppliedToUnappliedFilters,
    applySelectedFilters,
    updateCurrentPage,
    updateSSRStatus,
    fetchCarCounts,
    updateFilterTag,
    overrideSpecifiedAppliedFilter,
    updateCurrentPageWithTimestamp,
    updateIsRedirected,
    addAndApplyFilter,
    updateAppliedFilterTimestamp,
    clearAllFilters,
    updateIsFilterSettingsFetched,
    resetUnappliedCarCount,
    changeListName,
    updateCarListOptions,
    updateUnAppliedFilters,
    getOffScreenFilterCount,
    getBannerCarCount,
    setComingSoon,
    getBuyerTestimonial,
    updateBuyerTestimonialSSRStatus,
    postUserDownloadLink,
    setCarListingError,
    getNearestCities,
    getSimilarCarListing,
    fetchCityListAndBrands,
    updatePopularCityListSSRStatus,
    fetchOtherHubsInCity,
    reloadCarList,
    getSearchSuggestions,
    updateSimilarCarSSR,
    updateOtherCitySSR,
    fetchOtherYardCarsList,
    updateCarListForNonServiceable,
    fetchListingSeoData,
    fetchCmsSeoData,
    updateCmsData,
    setShowManualPincodePopup,
    fetchTaxChargesData
};

import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_CAR_LIST
    FETCH_CAR_LIST_SUCCESS
    FETCH_CAR_LIST_FAILURE

    FETCH_OTHER_YARD_CARS_LIST
    FETCH_OTHER_YARD_CARS_LIST_SUCCESS
    FETCH_OTHER_YARD_CARS_LIST_FAILURE

    UPDATE_SORT_ORDER
    CLEAR_UNAPPLIED_FILTERS_AND_SORT_ORDER
    APPLY_FILTERS_AND_SORT_ORDER

    RESET_CAR_LIST

    SET_ACTIVE_SCREEN
    SET_ACTIVE_TAB

    SET_DISPLAY_TYPE

    SET_DEFAULT_DYNAMIC_FILTER_SETTINGS

    UPDATE_COLLAPSIBLE_STATES
    UPDATE_UNAPPLIED_FILTERS
    UPDATE_APPLIED_FILTERS
    COPY_UNAPPLIED_FILTERS
    APPLY_SELECTED_FILTERS

    UPDATE_CURRENT_PAGE
    UPDATE_CURRENT_PAGE_WITH_TIMESTAMP

    UPDATE_SSR_STATUS

    PREPOPULATE_FILTERS

    FETCH_CAR_COUNTS
    FETCH_CAR_COUNTS_SUCCESS
    FETCH_CAR_COUNTS_FAILURE

    UPDATE_FILTER_TAG

    OVERRIDE_SPECIFIED_APPLIED_FILTER

    UPDATE_IS_REDIRECTED

    ADD_AND_APPLY_FILTER

    UPDATE_APPLIED_FILTER_TIMESTAMP

    CLEAR_ALL_FILTERS
    UPDATE_IS_FILTER_SETTINGS_FETCHED

    RESET_UNAPPLIED_CAR_COUNT

    CHANGE_LIST_NAME

    UPDATE_CAR_LIST_OPTIONS
    OFF_SCREEN_FILTER_COUNT

    FETCH_GA_BANNER_COUNT_SUCCESS
    COMING_SOON_POPUP

    SET_BUYER_TESTIMONIAL
    UPDATE_BUYER_TESTIMONIAL_SSR_STATUS
    SET_BUYER_TESTIMONIAL_FAILURE

    CAR_LISTING_ERROR
    NEAREST_CITIES

    FETCH_POPULAR_CITY_LIST_AND_BRANDS
    FETCH_POPULAR_CITY_LIST_AND_BRANDS_SUCCESS
    FETCH_POPULAR_CITY_LIST_AND_BRANDS_FAILURE

    UPDATE_POPULAR_CITY_LIST_SSR_STATUS
    
    FETCH_OTHER_HUBS_IN_CITY
    FETCH_OTHER_HUBS_IN_CITY_SUCCESS
    FETCH_OTHER_HUBS_IN_CITY_FAILURE
    RELOAD_CAR_LIST

    FETCH_SIMILAR_CAR_SUCCESS
    FETCH_SIMILAR_CAR_FAILURE
    
    UPDATE_SIMILAR_CAR_FILTER_SSR
    UPDATE_OTHER_CITY_FILTER_SSR
    UPDATE_LISTING_FAQ
    RESET_LISTING_FAQ
    SET_LISTING_FAQ_SSR

    UPDATE_CAR_LIST_FOR_NON_SERVICEABLE

    UPDATE_CMS_DATA
    
    SHOW_MANUAL_PINCODE_POPUP
`,
    {
        prefix: "carlist/"
    }
);

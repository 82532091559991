export const getPrimaryCity = (isGroupCity, groupCities) => {
    if (isGroupCity && (groupCities || []).length) {
        return groupCities.filter(el => el.isPrimary)[0] || null;
    }
    return null;
};

/* eslint-disable camelcase */
export const cityParser = (data) => {
    const cityParserData = (data || []).map((d) => {
        const { city_id, city_name, city_slug, icon_url, is_buy_used_car, is_buy_used_car_popular, is_featured,
            is_seo_city, pincode, realLifeImage, display_order, background_image, background_image_mobile, buy_used_car_url,
            buy_used_car_url_sec_cta, car_service_city, group_city, lat, lng, t0_city, state_code, home_inspection, groupCities, d2cCity,
            c2cEligibility} = d;
        return { city_id, city_name, city_slug, icon_url, is_buy_used_car, is_buy_used_car_popular, is_featured,
            is_seo_city, pincode, realLifeImage, display_order, background_image, background_image_mobile, buy_used_car_url,
            buy_used_car_url_sec_cta, car_service_city, is_virtual_city: false, group_city, lat, lng, t0_city, state_code, home_inspection, primaryGroupCity: getPrimaryCity(group_city, groupCities), d2cCity,
            c2cEligibility };
    });
    return cityParserData;
};

/* eslint-disable max-len */

import { createReducer } from "../../../utils/redux/create-reducer";
import { SCREEN_TYPES } from "./constants";
import { CAR_LIST_EVENT_NAMES } from "../../../constants/app-constants";
// import { PRIMARY_FILTER_PRIORITY } from "../../../utils/filters/constants";
import Types from "./types";
import { findByKey } from "../../../utils/helpers/find-by-key";
import { updateSimilarCarsContent } from "../../../utils/helpers/update-similar-cars-content";
import flattenCarList from "../../../utils/helpers/flatten-car-list";
import { FILTER_ENTITIES, RESETTABLE_FILTER_ENTITIES } from "../../../utils/filters/constants";
import sanitizeItemtoLowerCase from "../../../utils/helpers/sanitize-items-to-lowercase";
import { FILTER_V2_MAP_REVERSE, OFF_SCREEN_FILTERS_LIST } from "../../../utils/filters-v2-listing/constants";
import { SORT_TYPES } from "../../../constants/sort-types";
import { getOrderWisePopularLinks } from "../../../utils/helpers/get-order-wise-popular-links";
import { getOrderForInterlinkingFilters } from "../../../utils/helpers/get-order-for-interlinking-filters";

const getCheckedFilters = (selection, filter) => {
    return selection.value.map((item) => {
        const parsers = {
            [FILTER_ENTITIES.CAR_NAME]: flattenCarList
        };
        const parser = parsers[filter.keyName] || ((data) => data);
        //might need to check if there is a need to put fallback for this toLowerCase()
        const currentSelection = findByKey(
            parser(filter.data),
            "value",
            sanitizeItemtoLowerCase(item),
            (dynamicFilter, selectedFilter) => {
                return sanitizeItemtoLowerCase(dynamicFilter) === sanitizeItemtoLowerCase(selectedFilter);
            }
        );
        const computedSelection = {
            displayText: currentSelection.displayText,
            value: currentSelection.value
        };
        return computedSelection.value && computedSelection || null;
    }).filter((item) => item !== null);
};

const getSliderFilters = (field, selection) => {
    const values = selection.value.pop().split("-");
    return [
        {
            min: parseInt(values[0]),
            max: parseInt(values[1])
        }
    ];
};

const getCommonSettingsForAppliedFilter = (page = 0) => ({
    page,
    lastUpdatedAppliedFilters: (new Date()).getTime()
});

const getExistingStateFilter = (stateFilters) => {
    const filters = {};
    Object.keys(stateFilters).forEach((item) => {
        filters[item] = {
            ...stateFilters[item]
        };
    });
    return filters;
};

const parseSelection = ({ data, key, currentValue }) => {
    return data.filter((item) => {
        return !(sanitizeItemtoLowerCase(item[key]) === sanitizeItemtoLowerCase(currentValue[key]));
    });
};

export const INITIAL_STATE = {
    filtersAndSortOrder: {
        sortOrder: SORT_TYPES.bestMatch.items.default.payload
    },
    content: [],
    similarCars: {},
    isSSRSimilarCar: false,
    metaContent: {
        metaTitle: null,
        metaDescription: null,
        metaKeyWord: null,
        heading: null
    },
    interLinkingUrls: null,
    filters: null,
    unappliedOptions: {},
    appliedOptions: {
        sort: "bestmatch",
        serveWarrantyCount: true
    },
    cities: {},
    filterCollapsibleStates: null,
    isFilterSettingsFetched: false,
    totalCars: 0,
    totalPages: null,
    activeScreen: SCREEN_TYPES.listing,
    activeTab: null,
    error: null,
    displayType: null,
    isSSR: false,
    page: 0,
    pageSize: 20,
    isCarCountLoading: false,
    lastUpdatedAppliedFilters: null,
    lastSelectedFilterKey: null,
    unappliedCarCount: null,
    lastUpdatedUnappliedFilters: null,
    isRedirected: false,
    isLoading: false,
    listName: CAR_LIST_EVENT_NAMES.LISTING,
    showWarranty: false,
    offScreenFilterActiveCount: null,
    totalCarCount: "",
    bannerConfigData: {},
    ciAvailable: true,
    isComingSoon: false,
    buyerTestimonial: {},
    makeId: "",
    modelId: "",
    nearestCities: null,
    latestFilterKey: "",
    otherYardCarsList: {
        data: [],
        baseUrl: "",
        cities: {},
        hubs: {}
    },
    popularCityListsAndAvailableBrands: [],
    isPopularCityListSSR: false,
    otherHubsInCity: [],
    shouldReloadList: false,
    isSSROtherCity: false,
    listingFaqs: [],
    redirectUrl: "",
    popularLinks: [],
    showManualPincodePopup: false,
    cmsData: {
        footer: null,
        atf: null,
        h1: null,
        title: null,
        description: null,
        carListFaqs: []
        // isLoading:false
    },
    seoData: null,
    isFetched: false,
    cityId: null
};

export const fetchCarList = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true,
        isFetched: false
    };
};

// export const fetchCarListSuccess = (state = INITIAL_STATE, { data }) => {
//     const shouldSyncWarranty = !state.isSSR && (state.page === 0 || state.page === 1);
//     return {
//         ...state,
//         content: [...state.content, ...data.content],
//         metaContent: { ...data.metaContent },
//         totalCars: data.totalElements,
//         totalPages: data.totalPages,
//         interLinkingUrls: data.popularCategoryFilter,
//         isLoading: false,
//         error: null,
//         appliedOptions: {
//             ...state.appliedOptions,
//             serveWarrantyCount: shouldSyncWarranty
//         },
//         ...(shouldSyncWarranty && { showWarranty: data.renderWarrantyBanner })
//     };
// };

export const fetchCarListSuccess = (state = INITIAL_STATE, { data }) => {
    // const shouldSyncWarranty = !state.isSSR && (state.page === 0 || state.page === 1);
    const totalElements = data.page ? data.page.totalElements : 0;
    const searchAfter = data.page ? data.page.searchAfter : null;
    return {
        ...state,
        content: [...state.content, ...data.content],
        metaContent: { ...data.metaContent },
        baseUrl: data.baseUrl,
        totalCars: totalElements,
        totalPages: Math.ceil(totalElements / state.pageSize),
        searchAfter,
        cities: { ...state.cities, ...data.cities },
        hubs: { ...state.hubs, ...data.hubs },
        interLinkingUrls: getOrderWisePopularLinks(data.popularLinks) || [],
        // similarBrands: data.similarBrands,
        isLoading: false,
        error: null,
        // isBoostedListing: data.isBoostedListing,
        // appliedOptions: {
        //     ...state.appliedOptions,
        //     serveWarrantyCount: shouldSyncWarranty
        // },
        // ...(shouldSyncWarranty && { showWarranty: data.renderWarrantyBanner })
        seo: data.seo,
        breadCrumbs: data.breadCrumbs,
        popularLinks: getOrderForInterlinkingFilters(data.popularLinks),
        seoData: data.seoData,
        isFetched: true,
        cityId: data.cityId,
        ...(data.redirectUrl && { redirectUrl: data.redirectUrl })
    };
};

export const fetchCarListFailure = (state = INITIAL_STATE, { error, redirectUrl }) => {
    return {
        ...state,
        error,
        isLoading: false,
        ...(redirectUrl && { redirectUrl })
    };
};

export const updateSortOrder = (state = INITIAL_STATE, { sort }) => {
    return {
        ...state,
        appliedOptions: {
            ...state.appliedOptions,
            sort
        },
        ...getCommonSettingsForAppliedFilter()
    };
};

export const fetchOtherHubsInCity = (state = INITIAL_STATE) => {
    return { ...state };
};

export const fetchOtherHubsInCitySuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        otherHubsInCity: data
    };
};

export const fetchOtherHubsInCityFailure = (state = INITIAL_STATE) => {
    return {
        ...state
    };
};

export const clearUnappliedFiltersAndSortOrder = (state = INITIAL_STATE) => {
    const filters = {};
    Object.keys(state.filters).forEach((item) => {
        filters[item] = {
            ...state.filters[item],
            unapplied: []
        };
    });
    return {
        ...state,
        filters,
        unappliedOptions: {},
        activeTab: null
    };
};

export const applyFilterAndSortOrder = (state = INITIAL_STATE) => {
    return {
        ...state,
        filtersAndSortOrder: {
            ...state.filtersAndSortOrder,
            ...state.unappliedFiltersAndSortOrder
        },
        unappliedFiltersAndSortOrder: INITIAL_STATE.unappliedFiltersAndSortOrder
    };
};

export const resetCarList = (state = INITIAL_STATE) => {
    return {
        ...state,
        content: [],
        totalPages: null,
        appliedOptions: {
            ...state.appliedOptions,
            serveWarrantyCount: true
        },
        searchAfter: null,
        redirectUrl: "",
        page: 0
    };
};

export const setActiveScreen = (state = INITIAL_STATE, { activeScreen }) => {
    return { ...state, activeScreen };
};

export const setActiveTab = (state = INITIAL_STATE, { activeTab }) => {
    return { ...state, activeTab };
};

export const setDisplayType = (state = INITIAL_STATE, { displayType }) => {
    return { ...state, displayType };
};

export const setDefaultDynamicFilterSettings = (state = INITIAL_STATE, { data }) => {
    const filters = {};
    Object.keys(data).forEach((item) => {
        /**
         * this checks for three conditions
         * 1. if filterKey is not null and the key is present in the reset list
         * 2. if there are no filters present (first execution case)
         * 3. if there are filters present, but any extra key gets added from dynamic filters
         */
        if ((RESETTABLE_FILTER_ENTITIES.includes(item)) || !state.filters || (
            state.filters && !state.filters[item]
        )) {
            if (data[item]) {
                filters[item] = {
                    title: data[item].title,
                    apiKey: data[item].keyName,
                    apiSuffix: data[item].keySuffix || false,
                    renderingEnabled: data[item].renderingEnabled,
                    applied: [],
                    unapplied: []
                };
            }
        } else if (data[item] && state.filters && state.filters[item]) {
            filters[item] = {
                ...state.filters[item]
            };
        }
    });
    const filterCollapsibleStates = {};
    Object.keys(data).forEach((item) => {
        if (data[item]) {
            filterCollapsibleStates[item] = data[item].collapseResponse.collapsed;
        }
    });
    return {
        ...state,
        filters,
        isFilterSettingsFetched: true,
        filterCollapsibleStates,
        ...(state.filters && getCommonSettingsForAppliedFilter())
    };
};

export const updateCollapsibleStates = (state = INITIAL_STATE, { key }) => {
    return {
        ...state, filterCollapsibleStates: {
            ...state.filterCollapsibleStates,
            [key]: !state.filterCollapsibleStates[key]
        },
        lastSelectedFilterKey: key
    };
};

export const updateAppliedFilters = (
    state = INITIAL_STATE,
    { key, filter, isSelected, isSingleEntityFilter, isSlider }
) => {
    const appliedFilter = {
        displayText: filter.displayText,
        value: filter.value
    };
    let tempAppliedFilter = parseSelection({
        data: state.filters[key].applied,
        key: "value",
        currentValue: appliedFilter
    }).slice();

    if (!isSelected) {
        tempAppliedFilter.push(appliedFilter);
    }
    //use this flag for sliders
    if (isSlider) {
        tempAppliedFilter = [{
            max: filter.max,
            min: filter.min
        }];
    }
    //if its a filter with only one option, empty out the array
    if (isSelected && isSingleEntityFilter) tempAppliedFilter = [];
    return {
        ...state,
        filters: {
            ...state.filters,
            [key]: {
                ...state.filters[key],
                applied: tempAppliedFilter
            }
        },
        ...getCommonSettingsForAppliedFilter()
    };
};

export const copyAppliedToUnappliedFilters = (state = INITIAL_STATE) => {
    const filters = {};
    Object.keys(state.filters).forEach((item) => {
        filters[item] = {
            ...state.filters[item],
            unapplied: state.filters[item].applied.slice()
        };
    });
    return {
        ...state,
        filters,
        unappliedOptions: { ...state.appliedOptions }
    };
};

export const applySelectedFilters = (state = INITIAL_STATE) => {
    const filters = {};
    Object.keys(state.filters).forEach((item) => {
        filters[item] = {
            ...state.filters[item],
            applied: state.filters[item].unapplied.slice(),
            // applied: !state.filters[item].unapplied.length ? state.filters[item].applied : state.filters[item].unapplied.slice(),
            unapplied: []
        };
    });
    return {
        ...state,
        filters,
        appliedOptions: { ...state.appliedOptions, ...state.unappliedOptions },
        unappliedOptions: {},
        ...getCommonSettingsForAppliedFilter()
    };
};

export const updateCurrentPage = (state = INITIAL_STATE, { page }) => {
    return { ...state, page };
};

export const updateCurrentPageWithTimestamp = (state = INITIAL_STATE, { currentPage }) => {
    return { ...state, ...getCommonSettingsForAppliedFilter(currentPage) };
};

export const updateSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isSSR };
};

export const prepopulateFilters = (
    state = INITIAL_STATE,
    { filters: selectedFilters, dynamicFilters, prefilledFilters }
) => {
    const result = {};
    selectedFilters.map((item) => {
        const filter = dynamicFilters[item.field];
        if (filter) {
            let data = [];
            if (item.field === FILTER_ENTITIES.ASSURED) {
                data = [{
                    displayText: "Money-Back Guarantee",
                    value: true
                }];
            } else if (filter.type === "CHECKED") {
                data = getCheckedFilters(item, filter);
            }
            if (filter.type === "SLIDER") {
                data = getSliderFilters(item.field, item);
            }
            if (filter.type === "COMPOSITE") {
                // getCompositeFilters(item.field, item, filter)
                data = getSliderFilters(item.field, item);
            }
            result[item.field] = {
                applied: data
            };
        } else if (item.field === FILTER_ENTITIES.TOP_SELLING) {
            result.parkingYardId = {
                applied: [{ displayText: "Instantly Available", value: true }]
            };
        }

    });
    const filters = { ...prefilledFilters };
    Object.keys(result).forEach((item) => {
        filters[item] = {
            ...filters[item],
            applied: result[item].applied,
            unapplied: []
        };
    });
    return { ...state, filters };
};

export const fetchCarCountsInit = (state = INITIAL_STATE) => {
    return { ...state, isCarCountLoading: true };
};

export const fetchCarCountsSuccess = (state = INITIAL_STATE, { unappliedCarCount }) => {
    return { ...state, unappliedCarCount, isCarCountLoading: false };
};

export const fetchCarCountsFailure = (state = INITIAL_STATE) => {
    return { ...state, unappliedCarCount: null, isCarCountLoading: false };
};

export const fetchOtherYardCarsListInit = (state = INITIAL_STATE) => {
    return { ...state };
};

export const fetchOtherYardCarsListSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        otherYardCarsList: {
            data: data.content,
            baseUrl: data.baseUrl,
            cities: data.cities,
            hubs: data.hubs
        }
    };
};

export const fetchOtherYardCarsListFailure = (state = INITIAL_STATE) => {
    return {
        ...state
    };
};

export const updateFilterTag = (state = INITIAL_STATE, { key, filter }) => {

    const applied = parseSelection({
        data: state.filters[key].applied,
        key: "value",
        currentValue: filter
    }).slice();

    return {
        ...state,
        filters: {
            ...state.filters,
            [key]: {
                ...state.filters[key],
                applied: applied.slice()
            }
        },
        isSSR: false,
        ...getCommonSettingsForAppliedFilter()
    };
};

export const overrideSpecifiedAppliedFilter = (state = INITIAL_STATE,
    { key, filter, isRedirected = false }) => {
    const filters = {};
    Object.keys(state.filters).forEach((item) => {
        filters[item] = {
            ...state.filters[item],
            applied: item === key && [filter] || [],
            unapplied: []
        };
    });
    return {
        ...state,
        filters,
        isRedirected,
        latestFilterKey: key,
        ...getCommonSettingsForAppliedFilter()
    };
};

export const updateIsRedirected = (state = INITIAL_STATE, { isRedirected }) => {
    return { ...state, isRedirected };
};

export const addAndApplyFilter = (state = INITIAL_STATE, { key, value }) => {
    const existingFilters = getExistingStateFilter(state.filters);
    existingFilters[key].applied.push(value);
    return {
        ...state,
        filters: existingFilters,
        isSSR: false,
        ...getCommonSettingsForAppliedFilter()
    };
};

export const updateAppliedFilterTimestamp = (state = INITIAL_STATE,
    { lastUpdatedAppliedFilters }) => {
    return {
        ...state,
        lastUpdatedAppliedFilters
    };
};

export const clearAllFilters = (state = INITIAL_STATE) => {
    const filters = {};
    Object.keys(state.filters).forEach((item) => {
        filters[item] = {
            ...state.filters[item],
            unapplied: [],
            applied: []
        };
    });

    return {
        ...state,
        filters,
        appliedOptions: {
            ...INITIAL_STATE.unappliedOptions,
            sort: INITIAL_STATE.appliedOptions.sort
        },
        unappliedOptions: {
            ...INITIAL_STATE.unappliedOptions,
            sort: INITIAL_STATE.appliedOptions.sort
        },
        lastUpdatedUnappliedFilters: (new Date()).getTime(),
        ...getCommonSettingsForAppliedFilter()
    };
};

export const updateIsFilterSettingsFetched = (state = INITIAL_STATE,
    { isFilterSettingsFetched }) => {
    return { ...state, isFilterSettingsFetched };
};

export const resetUnappliedCarCount = (state = INITIAL_STATE) => {
    return { ...state, unappliedCarCount: null };
};

export const changeListName = (state = INITIAL_STATE, { listName }) => {
    return { ...state, listName };
};

export const updateCarListOptions = (state = INITIAL_STATE, { options, removeOptions = [] }) => {
    const initialOptions = { ...state.appliedOptions };
    removeOptions.forEach(i => delete initialOptions[i]);
    return { ...state, appliedOptions: { ...initialOptions, ...options } };
};

export const updateUnAppliedFilters = (
    state = INITIAL_STATE,
    { key, filter, isSelected, isSingleEntityFilter, isSlider }
) => {
    const appliedFilter = {
        displayText: filter.displayText,
        value: filter.value
    };
    let unappliedFilters = parseSelection({
        data: state.filters[key].unapplied,
        key: "value",
        currentValue: appliedFilter
    }).slice();

    if (!isSelected) {
        unappliedFilters.push(appliedFilter);
    }
    //use this flag for sliders
    if (isSlider) {
        unappliedFilters = [{
            max: filter.max,
            min: filter.min
        }];
    }
    //if its a filter with only one option, empty out the array
    if (isSelected && isSingleEntityFilter) unappliedFilters = [];
    return {
        ...state,
        filters: {
            ...state.filters,
            [key]: {
                ...state.filters[key],
                unapplied: unappliedFilters
            }
        },
        lastUpdatedUnappliedFilters: (new Date()).getTime()
    };
};

export const getOffScreenFilterCount = (state = INITIAL_STATE) => {
    let offScreen = 0;
    const filters = state.filters;
    Object.keys(state.filters).forEach((item) => {
        if (OFF_SCREEN_FILTERS_LIST.includes(FILTER_V2_MAP_REVERSE[item])) {
            if (filters[item].unapplied.length) {
                offScreen++;
            }
        }

    });
    return {
        ...state,
        offScreenFilterActiveCount: offScreen
    };
};

export const getBannerCarCount = (state = INITIAL_STATE, { count, ciAvailable, configData }) => {
    return {
        ...state,
        totalCarCount: count,
        ciAvailable,
        bannerConfigData: configData
    };
};

export const setComingSoon = (state = INITIAL_STATE, { isComingSoon }) => {
    return {
        ...state,
        isComingSoon
    };
};

export const setBuyerTestimonial = (state = INITIAL_STATE, { detail }) => {
    return {
        ...state,
        buyerTestimonial: {
            ...state.buyerTestimonial,
            data: detail
        }
    };
};

export const fetchGetBuyerTestimonialFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        buyerTestimonial: {}
    };
};

export const updateBuyerTestimonialSSRStatus = (state = INITIAL_STATE, { isBuyerTestimonialSSR }) => {
    return {
        ...state,
        buyerTestimonial: {
            ...state.buyerTestimonial,
            isBuyerTestimonialSSR
        }
    };
};

export const setCarListingError = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        error
    };
};
export const setNearestCities = (state = INITIAL_STATE, { nearestCities }) => {
    return {
        ...state,
        nearestCities
    };
};
export const updateOtherCitySSR = (state = INITIAL_STATE, { isSSROtherCity }) => {
    return {
        ...state,
        isSSROtherCity
    };
};

export const fetchCityListAndBrands = (state = INITIAL_STATE) => {
    return {
        ...state
    };
};

export const fetchCityListAndBrandsSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        popularCityListsAndAvailableBrands: data
    };
};

export const fetchCityListAndBrandsFailure = (state = INITIAL_STATE) => {
    return { ...state };
};

export const updatePopularCityListSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return { ...state, isPopularCityListSSR: isSSR };
};

export const reloadCarList = (state = INITIAL_STATE, { shouldReloadList }) => {
    return {
        ...state,
        shouldReloadList,
        cmsData: {},
        isFetched: false
    };
};

export const setSimilarCarSuccess = (state = INITIAL_STATE, { similarCars, updateSimilarCarContent }) => {
    return {
        ...state,
        similarCars: updateSimilarCarsContent(state, updateSimilarCarContent, similarCars)
    };
};
export const setSimilarCarFailure = (state = INITIAL_STATE) => {
    return {
        ...state
    };
};
export const updateSimilarCarSSR = (state = INITIAL_STATE, { isSSRSimilarCar }) => {
    return {
        ...state,
        isSSRSimilarCar
    };
};

export const updateCarListForNonServiceable = (state = INITIAL_STATE, { data }) => {

    return {
        ...state,
        ...INITIAL_STATE,
        totalPages: 0,
        totalCars: null,
        metaContent: { ...data.metaContent },
        breadCrumbs: data.breadCrumbs,
        seo: false
    };
};

export const setShowManualPincodePopup = (state = INITIAL_STATE, {data}) => {
    return {
        ...state,
        showManualPincodePopup: data
    };
};

export const updateCmsData = (state = INITIAL_STATE, { cmsData }) => {
    return {
        ...state,
        cmsData: {
            footer: (cmsData || {}).footerText,
            atf: (cmsData || {}).ATF,
            title: (cmsData || {}).title,
            h1: (cmsData || {}).h1,
            description: (cmsData || {}).description,
            carListFaqs: (cmsData || {}).faq
        }
    };
};

export const HANDLERS = {
    [Types.FETCH_CAR_LIST]: fetchCarList,
    [Types.FETCH_CAR_LIST_SUCCESS]: fetchCarListSuccess,
    [Types.FETCH_CAR_LIST_FAILURE]: fetchCarListFailure,
    [Types.UPDATE_SORT_ORDER]: updateSortOrder,
    [Types.CLEAR_UNAPPLIED_FILTERS_AND_SORT_ORDER]: clearUnappliedFiltersAndSortOrder,
    [Types.APPLY_FILTERS_AND_SORT_ORDER]: applyFilterAndSortOrder,
    [Types.RESET_CAR_LIST]: resetCarList,
    [Types.SET_ACTIVE_SCREEN]: setActiveScreen,
    [Types.SET_ACTIVE_TAB]: setActiveTab,
    [Types.SET_DISPLAY_TYPE]: setDisplayType,
    [Types.SET_DEFAULT_DYNAMIC_FILTER_SETTINGS]: setDefaultDynamicFilterSettings,
    [Types.UPDATE_COLLAPSIBLE_STATES]: updateCollapsibleStates,
    [Types.UPDATE_APPLIED_FILTERS]: updateAppliedFilters,
    [Types.COPY_UNAPPLIED_FILTERS]: copyAppliedToUnappliedFilters,
    [Types.APPLY_SELECTED_FILTERS]: applySelectedFilters,
    [Types.UPDATE_CURRENT_PAGE]: updateCurrentPage,
    [Types.UPDATE_CURRENT_PAGE_WITH_TIMESTAMP]: updateCurrentPageWithTimestamp,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus,
    [Types.PREPOPULATE_FILTERS]: prepopulateFilters,
    [Types.FETCH_CAR_COUNTS]: fetchCarCountsInit,
    [Types.FETCH_CAR_COUNTS_SUCCESS]: fetchCarCountsSuccess,
    [Types.FETCH_CAR_COUNTS_FAILURE]: fetchCarCountsFailure,
    [Types.UPDATE_FILTER_TAG]: updateFilterTag,
    [Types.OVERRIDE_SPECIFIED_APPLIED_FILTER]: overrideSpecifiedAppliedFilter,
    [Types.UPDATE_IS_REDIRECTED]: updateIsRedirected,
    [Types.ADD_AND_APPLY_FILTER]: addAndApplyFilter,
    [Types.UPDATE_APPLIED_FILTER_TIMESTAMP]: updateAppliedFilterTimestamp,
    [Types.CLEAR_ALL_FILTERS]: clearAllFilters,
    [Types.UPDATE_IS_FILTER_SETTINGS_FETCHED]: updateIsFilterSettingsFetched,
    [Types.RESET_UNAPPLIED_CAR_COUNT]: resetUnappliedCarCount,
    [Types.CHANGE_LIST_NAME]: changeListName,
    [Types.UPDATE_CAR_LIST_OPTIONS]: updateCarListOptions,
    [Types.UPDATE_UNAPPLIED_FILTERS]: updateUnAppliedFilters,
    [Types.OFF_SCREEN_FILTER_COUNT]: getOffScreenFilterCount,
    [Types.FETCH_GA_BANNER_COUNT_SUCCESS]: getBannerCarCount,
    [Types.COMING_SOON_POPUP]: setComingSoon,
    [Types.SET_BUYER_TESTIMONIAL]: setBuyerTestimonial,
    [Types.UPDATE_BUYER_TESTIMONIAL_SSR_STATUS]: updateBuyerTestimonialSSRStatus,
    [Types.SET_BUYER_TESTIMONIAL_FAILURE]: fetchGetBuyerTestimonialFailure,
    [Types.CAR_LISTING_ERROR]: setCarListingError,
    [Types.NEAREST_CITIES]: setNearestCities,
    [Types.FETCH_OTHER_YARD_CARS_LIST]: fetchOtherYardCarsListInit,
    [Types.FETCH_OTHER_YARD_CARS_LIST_FAILURE]: fetchOtherYardCarsListFailure,
    [Types.FETCH_OTHER_YARD_CARS_LIST_SUCCESS]: fetchOtherYardCarsListSuccess,
    [Types.FETCH_POPULAR_CITY_LIST_AND_BRANDS]: fetchCityListAndBrands,
    [Types.FETCH_POPULAR_CITY_LIST_AND_BRANDS_FAILURE]: fetchCityListAndBrandsFailure,
    [Types.FETCH_POPULAR_CITY_LIST_AND_BRANDS_SUCCESS]: fetchCityListAndBrandsSuccess,
    [Types.UPDATE_POPULAR_CITY_LIST_SSR_STATUS]: updatePopularCityListSSRStatus,
    [Types.FETCH_OTHER_HUBS_IN_CITY]: fetchOtherHubsInCity,
    [Types.FETCH_OTHER_HUBS_IN_CITY_FAILURE]: fetchOtherHubsInCityFailure,
    [Types.FETCH_OTHER_HUBS_IN_CITY_SUCCESS]: fetchOtherHubsInCitySuccess,
    [Types.RELOAD_CAR_LIST]: reloadCarList,
    [Types.FETCH_SIMILAR_CAR_SUCCESS]: setSimilarCarSuccess,
    [Types.FETCH_SIMILAR_CAR_FAILURE]: setSimilarCarFailure,
    [Types.UPDATE_SIMILAR_CAR_FILTER_SSR]: updateSimilarCarSSR,
    [Types.UPDATE_OTHER_CITY_FILTER_SSR]: updateOtherCitySSR,
    [Types.UPDATE_CAR_LIST_FOR_NON_SERVICEABLE]: updateCarListForNonServiceable,
    [Types.UPDATE_CMS_DATA]: updateCmsData,
    [Types.SHOW_MANUAL_PINCODE_POPUP]: setShowManualPincodePopup
};

export default createReducer(INITIAL_STATE, HANDLERS);

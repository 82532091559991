/* eslint-disable no-magic-numbers */
/* eslint-disable max-params */
/* eslint-disable no-unused-expressions */

const makeScript = (src, id, async) => (
    function(d, s) {
        if (d.getElementById(id)) {return;}
        const element = d.getElementsByTagName(s)[0];
        const jscript = d.createElement(s);
        jscript.src = src;
        jscript.id = id;
        if (async) {
            jscript.async = true;
        }
        element.parentNode.insertBefore(jscript, element);
    }(document, "script"));

// eslint-disable-next-line one-var
export const loadGoogleTagManager = () => {(function(w, d, s, l, i) {w[l] = w[l] || []; w[l].push({"gtm.start": new Date().getTime(), event: "gtm.js"}); const f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l !== "dataLayer" ? `&l=${  l}` : ""; j.async = true; j.src = `//www.googletagmanager.com/gtm.js?id=${i  }${dl}`; f.parentNode.insertBefore(j, f);})(window, document, "script", "dataLayer", `${process.env.GTM_ID}`);};

// export const loadGoogleMap = () => makeScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLACES_API_KEY}&libraries=places`, "google-place");

// export const loadGooglePlatform = () => makeScript("https://apis.google.com/js/platform.js", "google-platform");

export const loadClevertap = (callback) => {
    window.clevertap = { event: [], profile: [], account: [], onUserLogin: [], notifications: [], privacy: [] };
    window.clevertap.account.push({ "id": process.env.CLEVERTAP_ACCOUNT_ID });
    window.clevertap.privacy.push({ optOut: false });
    window.clevertap.privacy.push({ useIP: false });
    (function () {
        const wzrk = document.createElement("script");
        wzrk.type = "text/javascript"; wzrk.async = true;
        wzrk.src = `${document.location.protocol === "https:" ? "https://d2r1yp2w7bby2u.cloudfront.net" : "http://static.clevertap.com"}/js/a.js`;
        wzrk.onload = callback;
        wzrk.onerror = () => callback(false);
        const s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(wzrk, s);
    })();
};

export const loadFinvuSdk = () => makeScript(process.env.FINVU_SDK_URL, "finvu", true);

export const removeFinvuSdk = () => (
    function(d, id) {
        const element = d.getElementById(id);
        if (element) {
            delete window.finvuClient;
            element.remove();
        }

    }(document, "finvu"));

/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable max-len */
import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";
import { subscribe } from "valtio/vanilla";
import EventHandler from "../../utils/event-handler";
import {
    ALTERNATE_LEAD_CITIES,
    COOKIE_KEYS,
    NUMBER,
    USER_LOGIN_STATUS,
    USER_LOGIN_MEDIUM
} from "../../constants/app-constants";
// import OPTIMIZE_CONSTANTS, { EXPERIMENT_IDENTIFIER } from "../../constants/optimize-constants";
import parseCookie from "../../utils/helpers/parse-cookie";
import deleteCookie from "../../utils/helpers/delete-cookie";
import saveCookie from "../../utils/helpers/save-cookie";
import { tokenState } from "../../service/middleware-config";
import { loadClevertap } from "./dynamic-scripts";
import parseLocationSearchParams from "../../utils/helpers/parse-location-search-params";
import { userClientIdInstance } from "../../utils/helpers/generate-client-id";
import { statsigInitialization } from "../../utils/statsig-ab-utils/initialize-user-csr";
import { useLocation } from "react-router";
import getFromLocalStorage from "../../utils/helpers/get-from-local-storage";
import { OG_IMAGE_TYPES, OGImage } from "../shared/og-image";

const Configuration = ({
    children,
    selectedCity,
    fetchProfileConnect,
    setGAIdConnect,
    // setExperimentConnect,
    pincode,
    checkAuthTokenConnect,
    userId,
    secureToken,
    getVariantsFromB2COMSConnect,
    // applicableCityForSellOnline,
    gaId,
    isLoggedIn,
    getCouponForUserConnect,
    getOptedCouponForUserConnect,
    isLoginFetched,
    mobile,
    mapClientIdConnect,
    isUserSessionValid,
    statsigId,
    statsigConfig,
    updateStatsigConfigConnect,
    updateStatsigTimeStampConnect,
    gaIdError,
    migrateExistingLeadDataConnect,
    type
}) => {
    const location = useLocation();
    const queryObj = parseLocationSearchParams(location.search);
    const { statsigInitialized = false, statsigClientIdUpdated = false } = statsigConfig || {};
    /*
        initializes event handler class
    */
    useEffect(() => {
        const eventInstance = EventHandler.getInstance();
        window.EventHandler = eventInstance;
    }, []);

    useEffect(() => {
        userClientIdInstance.generateClientId((error, clientId) => {
            if (error) {
                setGAIdConnect(null);
            } else if (clientId) {
                setGAIdConnect(clientId);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            loadClevertap();
        }
    }, [isLoggedIn]);

    /**
     *  initialize rto event
     */
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "optimize.rto_city.activate"
        });
    }, []);

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        if (isUserSessionValid) fetchProfileConnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserSessionValid]);

    useEffect(() => {
        const unsubscribe = subscribe(tokenState, () => {
            if (!tokenState.isTokenValid) {
                checkAuthTokenConnect();
                tokenState.isTokenValid = true;
            }
        });
        // Unsubscribe by calling the result
        return () => {
            unsubscribe();
        };
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [tokenState.isTokenValid]);

    // useEffect(() => {
    //     if (selectedCity.id) {
    //         if (!isBuyCarPincodeConfigSSR && pincode) fetchBuyCarPincodeConfigConnect(pincode);
    //     }
    //     updateSSRStatusPincodeConfigConnect(false);

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedCity.id && selectedCity.id.toString()]);

    const checkCouponStatus = () => {
        getCouponForUserConnect(userId, secureToken);
        getOptedCouponForUserConnect(userId, secureToken);
    };

    useEffect(() => {
        if (isLoggedIn && userId && secureToken && gaId) {
            checkCouponStatus(userId, secureToken);
            mapClientIdConnect({ clientId: gaId, secureToken });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, secureToken, userId]);

    //trigger optimize lead experiment for selected cities
    useEffect(() => {
        if (selectedCity.id && ALTERNATE_LEAD_CITIES.includes(selectedCity.id)) {
            window.dataLayer.push({ event: "optimize.lead.activate" });
        }
    }, [selectedCity.id]);

    useEffect(() => {
        if (parseCookie("book_now")) {
            deleteCookie("book_now");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const statsigCallback = () => {
        updateStatsigConfigConnect({ statsigInitialized: true });
        if (gaId && !gaIdError) {
            updateStatsigTimeStampConnect();
        }
    };

    const statsigMsgCallback = (msg) => {
        updateStatsigConfigConnect({ statsigCallbackMsg: msg });
    };

    useEffect(() => {
        const { id } = selectedCity || {};
        const cityId = parseInt(id);
        const initateStatsig = async () => {
            if (gaId) {
                updateStatsigConfigConnect({ statsigClientIdUpdated: true });
            }
            await statsigInitialization({ city: cityId, statsigId, clientId: gaId, successCallback: statsigCallback, updateResponse: statsigMsgCallback });
        };

        initateStatsig();

    }, []);

    useEffect(() => {
        if (type === "mobile" && (statsigId || gaId)) {
            try {
                const rootMobile = JSON.parse(getFromLocalStorage("persist:rootMobile"));
                const { leadData = "" } = rootMobile || {};
                if (leadData) {
                    migrateExistingLeadDataConnect(JSON.parse(leadData), statsigId, gaId);
                }
                // eslint-disable-next-line no-empty
            } catch (error) { }
        }

    }, []);

    useEffect(() => {

        if (!statsigClientIdUpdated && gaId && statsigInitialized) {
            const { id } = selectedCity || {};
            const cityId = parseInt(id);

            const updateStatsigUser = async () => {
                await statsigInitialization({ city: cityId, statsigId, clientId: gaId, updateUser: true });
                updateStatsigTimeStampConnect();
            };

            updateStatsigUser();
            updateStatsigConfigConnect({ statsigClientIdUpdated: true });
        } else if (statsigInitialized && gaIdError) {
            updateStatsigTimeStampConnect();
        }

    }, [statsigInitialized, gaId, gaIdError]);

    useEffect(() => {
        window.dataLayer.push({
            user_id: mobile,
            user_status: isLoggedIn ? USER_LOGIN_STATUS.LOGGED_IN : USER_LOGIN_STATUS.LOGGED_OUT,
            login_type: isLoggedIn ? USER_LOGIN_MEDIUM.PHONE : USER_LOGIN_MEDIUM.NONE
        });
        if (isLoggedIn) {
            window.dataLayer.push({ in_user_id: userId });
        } else {
            window.dataLayer.push({ in_user_id: null });
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (location.search) {
            let queryParams = location.search;
            queryParams = queryParams.split("?")[1];
            queryParams = queryParams.split("&");
            queryParams = queryParams.map(el => {
                el = el.split("=");
                return el;
            });
            let query = queryParams.reduce((prev, curr) => { prev[curr[0]] = curr[1]; return prev; }, {});
            query = {
                "query_param": query
            };
            const lastClickAttribute = parseCookie(COOKIE_KEYS.LAST_CLICK_ATTRIBUTE);
            const firstClickAttribute = parseCookie(COOKIE_KEYS.FIRST_CLICK_ATTRIBUTE);
            if (!lastClickAttribute) {
                saveCookie(COOKIE_KEYS.LAST_CLICK_ATTRIBUTE, JSON.stringify(query), 1);
            }
            if (!firstClickAttribute) {
                saveCookie(COOKIE_KEYS.FIRST_CLICK_ATTRIBUTE, JSON.stringify(query), NUMBER.THIRTY);
            }
        }
    }, []);

    useEffect(() => {
        if (isLoginFetched) getVariantsFromB2COMSConnect(secureToken, pincode, gaId); //API will run with or without token, isLoginFetched is used to prevent multiple API call
    }, [pincode, secureToken, isLoginFetched]);

    const getScriptTags = () => {
        let scriptTags = [];
        if (!queryObj.disableGtm && !queryObj.deferGtm) {
            scriptTags = scriptTags.concat({
                type: "text/javascript",
                innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${process.env.GTM_ID}');`
            });
        }
        if (queryObj.deferGtm) {
            scriptTags = scriptTags.concat({
                type: "text/javascript",
                innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
    '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${process.env.GTM_ID}');`
            });
        }
        //         if (!queryObj.disableSnippet) {
        //             scriptTags = scriptTags.concat({
        //                 type: "text/javascript",
        //                 innerHTML: `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
        // h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
        // (a[n]=a[n]||[]).hide=h;setTimeout(function()
        // {i();h.end=null},c);h.timeout=c;
        // })(window,document.documentElement,'async-hide','dataLayer',4000,{'${process.env.OPTIMISE_ID}':true});`
        //             });
        //         }
        if (!queryObj.disableGa && !queryObj.deferGa) {
            scriptTags = scriptTags.concat({
                type: "text/javascript",
                innerHTML: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function()
{ (i[r].q=i[r].q||[]).push(arguments)}
,i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
ga('create', '${process.env.GA_ID}', 'auto');`
            });
        }
        if (queryObj.deferGa) {
            scriptTags = scriptTags.concat({
                type: "text/javascript",
                innerHTML: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function()
{ (i[r].q=i[r].q||[]).push(arguments)}
,i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.defer=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
ga('create', '${process.env.GA_ID}', 'auto');`
            });
        }
        return scriptTags;
    };

    return (
        <Fragment>
            {
                <Helmet
                    script={getScriptTags()}>
                    {process.env.HOST_ENV === "PRODUCTION" && <meta name="facebook-domain-verification" content="zedoskz9z2kgib48fyopeheaincj11" />}
                </Helmet>
            }
            <OGImage type={OG_IMAGE_TYPES.superapp} />

            {children}
        </Fragment>
    );
};

Configuration.propTypes = {
    children: PropTypes.any.isRequired,
    fetchProfileConnect: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool,
    selectedCity: PropTypes.object,
    setGAIdConnect: PropTypes.func,
    fetchFilterV2Connect: PropTypes.func,
    type: PropTypes.string,
    setExperimentConnect: PropTypes.func,
    pincode: PropTypes.number,
    checkAuthTokenConnect: PropTypes.func,
    fetchFiltersConnect: PropTypes.func,
    userId: PropTypes.string,
    secureToken: PropTypes.string,
    getVariantsFromB2COMSConnect: PropTypes.func,
    // applicableCityForSellOnline: PropTypes.array,
    getCouponForUserConnect: PropTypes.func,
    getOptedCouponForUserConnect: PropTypes.func,
    gaId: PropTypes.string,
    isLoginFetched: PropTypes.bool,
    mobile: PropTypes.string,
    mapClientIdConnect: PropTypes.func,
    isUserSessionValid: PropTypes.bool,
    setStatsigInitializationConnect: PropTypes.func,
    statsigConfig: PropTypes.object,
    statsigId: PropTypes.string,
    updateStatsigConfigConnect: PropTypes.func,
    updateStatsigTimeStampConnect: PropTypes.func,
    gaIdError: PropTypes.string,
    migrateExistingLeadDataConnect: PropTypes.func
};

export default Configuration;

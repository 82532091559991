// import { budgetBucketFinder } from "../utils/helpers/budget-bucket";

import { budgetBucketFinder } from "../utils/helpers/budget-bucket";

const cleverTap = {

    // event name constants
    eventNames: {
        CAR_RESERVED_NOTIFY_ME: "Notify_click"
    },

    // eslint-disable-next-line complexity
    login: ({ user }) => {
        if (window && !window.clevertap) return;
        window.clevertap.onUserLogin.push({
            Site: {
                Phone: (user && user.mobile) ? `+91${user.mobile}` : "",
                Name: user && user.name || "",
                ["User ID"]: user && user.userId || "",
                Email: user && user.email || "",
                Country: "IN"
                // Identity: (user && user.mobile) ? `+91${user.mobile}` : ""
            }
        });
    },

    createAnonymousUserProfile: ({
        identity
    }) => {
        if (window && !window.clevertap) return;
        window.clevertap.onUserLogin.push({
            Site: {
                Country: "IN",
                Identity: identity
            }
        });
    },

    updateProfileForNotifiFlow: (data) => {
        if (window && !window.clevertap) return;
        window.clevertap.profile.push({
            "Site": {
                ...data
            }
        });
    },

    // eslint-disable-next-line complexity
    prepareData: (carDetailsContent = {}) => {
        if (carDetailsContent) {
            const {
                appointmentId,
                listingPrice,
                hub,
                city,
                year,
                make,
                model,
                media: { gallery: { images = [] } = {} } = {},
                carName,
                fuelType,
                bodyType,
                transmission,
                transmissionType,
                status,
                listingImage: { bgRemovedUri } = {}
            } = carDetailsContent || {};
            return {
                carName: carName || "",
                year: year || "",
                make: make || "",
                model: model || "",
                price: listingPrice || 0,
                appointmentId: appointmentId || "",
                fuelType: fuelType || "",
                transmission: transmission || transmissionType || "",
                bodyType: bodyType || "",
                city: city || "",
                hubId: (hub || {}).legacyId,
                imageUrl: (images[0] || {}).image || bgRemovedUri,
                cars24Hub: (hub || {}).name,
                budgetBucket: budgetBucketFinder(listingPrice),
                car_status: status
            };
        }
        return {};
    },

    /**
     * Tracks events
     *
     * @param {string} eventName one of cleverTap.eventNames
     * @param {object} data event data
     * @return {void} nothing
     */

    trackEvent: (eventName, data) => {
        if (window && !window.clevertap) return;
        window.clevertap.event.push(eventName, data);
    },

    trackCarDetailsEvent: (eventName, data) => {
        const { carDetailsContent, ...rest } = data;
        cleverTap.trackEvent(eventName, {
            ...cleverTap.prepareData(carDetailsContent),
            ...rest
        });
    }
};

export default cleverTap;

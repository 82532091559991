import Types from "./types";
import { CouponService } from "../../../service/coupon-service-api";
import { updateSSRAppliedFilters } from "../filters/action";
import { CatalogGatewayService } from "../../../service/catalog-gateway";
import { CarService } from "../../../service/car-service";
import { UsedCarService } from "../../../service/used-car-service";
import { CmsServiceApi } from "../../../service/cms-service";
import { CatalogUserProfileService } from "../../../service/catalog-user-profile";

const fetchCarListInit = () => ({
    type: Types.FETCH_CAR_LIST
});

const fetchCarListSuccess = (data) => ({
    type: Types.FETCH_CAR_LIST_SUCCESS,
    data
});

const fetchCarListFailure = (error, redirectUrl) => ({ type: Types.FETCH_CAR_LIST_FAILURE, error, redirectUrl });

const fetchCarList = (data) => (dispatch) => {
    dispatch(fetchCarListInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCarlist(data)
            .then(response => {
                if (response.data.redirectUrl) {
                    dispatch(fetchCarListFailure(null, response.data.redirectUrl));
                } else if (response.data) {
                    dispatch(fetchCarListSuccess(response.data));
                    if (response.data.appliedFilter) {
                        dispatch(updateSSRAppliedFilters(response.data.appliedFilter));
                    }
                    resolve(response.data);
                } else {
                    dispatch(fetchCarListFailure("no data available"));
                    reject("no data available");
                }
            }).catch(error => {
                dispatch(fetchCarListFailure(error));
                reject(error);
            });
    });
};

const fetchOtherYardCarsListInit = () => ({
    type: Types.FETCH_OTHER_YARD_CARS_LIST
});

const fetchOtherYardCarsListSuccess = (data) => ({
    type: Types.FETCH_OTHER_YARD_CARS_LIST_SUCCESS,
    data
});

const fetchOtherYardCarsListFailure = (error) => ({ type: Types.FETCH_OTHER_YARD_CARS_LIST_FAILURE, error });

const fetchOtherYardCarsList = (data) => (dispatch) => {
    dispatch(fetchOtherYardCarsListInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCarlist(data)
            .then(response => {
                dispatch(fetchOtherYardCarsListSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchOtherYardCarsListFailure(error));
                reject(error);
            });
    });
};

const updateSortOrder = (sort) => ({
    type: Types.UPDATE_SORT_ORDER,
    sort
});

const resetCarList = () => ({
    type: Types.RESET_CAR_LIST
});

const setActiveScreen = (activeScreen) => ({
    type: Types.SET_ACTIVE_SCREEN,
    activeScreen
});

const setActiveTab = (activeTab) => ({
    type: Types.SET_ACTIVE_TAB,
    activeTab
});

const updateCurrentPage = (page) => ({
    type: Types.UPDATE_CURRENT_PAGE,
    page
});

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const fetchCarCountsInit = () => ({
    type: Types.FETCH_CAR_COUNTS
});

const fetchCarCountsSuccess = (unappliedCarCount) => ({
    type: Types.FETCH_CAR_COUNTS_SUCCESS,
    ...unappliedCarCount
});

const fetchCarCountsFailure = () => ({ type: Types.FETCH_CAR_COUNTS_FAILURE });

const fetchCarCounts = (params, allInventory = false) => (dispatch) => {
    dispatch(fetchCarCountsInit());
    return new Promise((resolve, reject) => {
        UsedCarService.fetchCarCounts(params, allInventory)
            .then(response => {
                dispatch(fetchCarCountsSuccess(response.data.data));
                resolve(response);
            }).catch((error) => {
                dispatch(fetchCarCountsFailure());
                reject(error);
            });
    });
};

const updateIsRedirected = (isRedirected) => ({
    type: Types.UPDATE_IS_REDIRECTED,
    isRedirected
});

const updateCarListOptions = (options, removeOptions) => ({
    type: Types.UPDATE_CAR_LIST_OPTIONS,
    options,
    removeOptions
});

const setUserBannerStatus = (userBannerStatus) => ({
    type: Types.USER_BANNER_STATUS,
    userBannerStatus
});

const setActiveBanner = (activeBanner) => ({
    type: Types.ACTIVE_BANNER,
    activeBanner
});

const setAppliedBannerFilter = (appledBannerFilter) => ({
    type: Types.APPLIED_BANNER_FILTER,
    appledBannerFilter
});

const clearAppliedBannerFilter = () => ({
    type: Types.CLEAR_APPLIED_BANNER_FILTER
});

const setCFormIcon = (cardIndex) => ({
    type: Types.SET_CF_FORM_ICON,
    cardIndex
});

const reloadCarList = ({shouldReloadList, moveToListing}) => ({
    type: Types.RELOAD_CAR_LIST,
    shouldReloadList,
    moveToListing
});

const fetchgetBannerCarCountSuccess = (data) => ({
    type: Types.FETCH_GA_BANNER_COUNT_SUCCESS,
    ...data
});

const getBannerCarCount = (pinId, allInventory = false) => (dispatch) => {
    return new Promise((resolve, reject) => {
        UsedCarService.fetchBannerCarCounts(pinId, allInventory)
            .then(res => {
                resolve(res.data.data);
                dispatch(fetchgetBannerCarCountSuccess(res.data.data));
            }).catch(error => {
                reject(error);
                dispatch(fetchgetBannerCarCountSuccess(0));
            });
    });
};

const setComingSoon = (isComingSoon) => ({
    type: Types.COMING_SOON_POPUP,
    isComingSoon
});

const couponClientMapping = (data, token) => () => {
    return new Promise((resolve, reject) => {
        CouponService.clientMapping(data, token)
            .then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const fetchGetCouponForUser = (availableCoupons) => ({
    type: Types.AVAILABLE_COUPONS_FOR_USER,
    availableCoupons
});

const getCouponForUser = (userId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CouponService.getCouponForUser(userId, token)
            .then(res => {
                resolve(res.data);
                dispatch(fetchGetCouponForUser(res.data));
            }).catch(error => {
                reject(error);
                dispatch(fetchGetCouponForUser([]));
            });
    });
};

const fetchGetOptedCouponForUser = (appliedCoupons) => ({
    type: Types.APPLIED_COUPONS_FOR_USER,
    appliedCoupons
});

const getOptedCouponForUser = (userId, token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CouponService.getOptedCouponForUser(userId, token)
            .then(res => {
                resolve(res.data);
                dispatch(fetchGetOptedCouponForUser(res.data));
            }).catch(error => {
                reject(error);
                dispatch(fetchGetOptedCouponForUser(null));
            });
    });
};

const applyOptedCoupon = (data, token) => () => {
    return new Promise((resolve, reject) => {
        CouponService.postOptCouponForUser(data, token)
            .then(res => {
                resolve(res.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const updateBuyerTestimonialSSRStatus = (isBuyerTestimonialSSR) => ({
    type: Types.UPDATE_BUYER_TESTIMONIAL_SSR_STATUS,
    isBuyerTestimonialSSR
});

const fetchGetBuyerTestimonial = ({ detail }) => ({
    type: Types.SET_BUYER_TESTIMONIAL,
    detail
});

const fetchGetBuyerTestimonialFailure = () => ({
    type: Types.SET_BUYER_TESTIMONIAL_FAILURE
});

const getBuyerTestimonial = ({ makeId, modelId, cityId }) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CarService.buyerTestimonial({makeId, modelId, cityId})
            .then(res => {
                resolve(res.data);
                dispatch(fetchGetBuyerTestimonial(res.data));
            }).catch(error => {
                reject(error);
                dispatch(fetchGetBuyerTestimonialFailure());
            });
    });
};

const fetchNearestCities = (nearestCities) => ({
    type: Types.NEAREST_CITIES,
    nearestCities
});

const getNearestCities = (url) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getNearestcities(url)
            .then(res => {
                resolve(res.data);
                dispatch(fetchNearestCities(res.data));
            }).catch(error => {
                reject(error);
                dispatch(fetchNearestCities(null));
            });
    });
};
const updateOtherCitySSR = (isSSROtherCity) => ({
    type: Types.UPDATE_OTHER_CITY_FILTER_SSR,
    isSSROtherCity
});

const setCarListingError = (error) => ({
    type: Types.CAR_LISTING_ERROR,
    error
});

const fetchSimilarCarInit  = () => ({
    type: Types.FETCH_SIMILAR_CAR_INIT
});

const fetchSimialCarSuccess  = (similarCars, updateSimilarCarContent) => ({
    type: Types.FETCH_SIMILAR_CAR_SUCCESS,
    similarCars,
    updateSimilarCarContent
});
const fetchSimialCaFailure = (error) => ({
    type: Types.FETCH_SIMILAR_CAR_FAILURE,
    error
});

const getSimilarCarListing = (payload, updateSimilarCarContent = false) => (dispatch) => {
    dispatch(fetchSimilarCarInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCarlist(payload)
            .then(response => {
                resolve(response);
                dispatch(fetchSimialCarSuccess(response.data, updateSimilarCarContent));
            }).catch(error => {
                reject(error);
                dispatch(fetchSimialCaFailure(error));
            });
    });
};
const updateSimilarCarSSR = (isSSRSimilarCar) => ({
    type: Types.UPDATE_SIMILAR_CAR_FILTER_SSR,
    isSSRSimilarCar
});

const setPAOfferFilter = (data) => ({
    type: Types.SET_PA_OFFER_FILTER,
    data
});

const shareLocationPopup = (isShareLocationPopup) => ({
    type: Types.SHARE_LOCATION_POPUP,
    isShareLocationPopup
});

const userHubLocation = (userLocation) => ({
    type: Types.USER_HUB_LOCATION,
    userLocation
});

const updateCarListForNonServiceable = (data) => ({
    type: Types.UPDATE_CAR_LIST_FOR_NON_SERVICEABLE,
    data
});

const updateListingPageVisited = (isListingPageVisited) => ({
    type: Types.UPDATE_LISTING_PAGE_VISITED,
    isListingPageVisited
});

const updateFilterToolTipShowed = (isFilterTooltipShowed) => ({
    type: Types.UPDATE_FILTER_TOOL_TIP_SHOWED,
    isFilterTooltipShowed
});

const fetchSimilarCarsForListingCarSuccess = (data) => ({
    type: Types.FETCH_SIMILAR_CARS_FOR_LISTING_CAR_SUCCESS,
    data
});
const fetchSimilarCarsForListingCarFailure = () => ({ type: Types.FETCH_SIMILAR_CARS_FOR_LISTING_CAR_FAILURE });

const fetchSimilarCarsForListingCar = (appointmentId, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getSimilarCarsForListingCars(appointmentId, data)
            .then(response => {
                dispatch(fetchSimilarCarsForListingCarSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchSimilarCarsForListingCarFailure());
                reject(error);
            });
    });
};

const changeLocationByNarby = ({isLocationChanged, cityName }) => ({
    type: Types.CHANGE_LOCATION_BY_NEARBY,
    isLocationChanged,
    cityName
});
const updateClickedCarIndex = (carIndex) => ({
    type: Types.UPDATE_CLICKED_CAR_INDEX,
    carIndex
});

const fetchOtherCarsInit = () => ({
    type: Types.FETCH_OTHER_CARS
});

const fetchOtherCarsSuccess = (otherCarsData) => ({
    type: Types.FETCH_OTHER_CARS_SUCCESS,
    otherCarsData
});
const fetchOtherCarsFailure = () => ({ type: Types.FETCH_OTHER_CARS_FAILURE });

const fetchOtherCars = (appointmentId, params) => (dispatch) => {
    dispatch(fetchOtherCarsInit());
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getOtherCars(appointmentId, params)
            .then(response => {
                dispatch(fetchOtherCarsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchOtherCarsFailure());
                reject(error);
            });
    });
};

const updateOtherCarsSSRStatus = (isSSR) => ({
    type: Types.UPDATE_OTHER_CARS_SSR_STATUS,
    isSSR
});

const updateTotalCarSize = (totalCars) => ({
    type: Types.UPDATE_TOTAL_CAR_SIZE,
    totalCars
});

const updateOtherCarsListing = (otherCarsForListing) => ({
    type: Types.UPDATE_OTHER_CARS_LISTING,
    otherCarsForListing
});

const updateCmsData = (cmsData) => ({
    type: Types.UPDATE_CMS_DATA,
    cmsData
});

const fetchListingSeoData = (data) => () => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getCarListingSeoContent(data)
            .then(response => {
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const fetchCmsSeoData = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CmsServiceApi.getCmsData(data)
            .then(response => {
                dispatch(updateCmsData(response.data.data));
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const updateBestMatchesSnackBarViewStatus = (data) => ({
    type: Types.UPDATE_BEST_MATCH_SNACK_BAR_VIEW_STATUS,
    data
});

const updateRecommendationTransferStatus = (data) => ({
    type: Types.UPDATE_RECOMMENDATION_TRANSFER_STATUS,
    data
});

const transferRecommendations = (token, clientId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CatalogUserProfileService.transferRecommendations({token, clientId})
            .then(response => {
                dispatch(updateRecommendationTransferStatus(true));
                resolve(response.data);
            }).catch(error => {
                reject(error);
            });
    });
};

const updateMongoListeningConditions = (data) => ({
    type: Types.UPDATE_MONGO_LISTENING_CONDITIONS,
    data
});

const updateFilterStickinessCondition = (shouldFilterBeSticky) => ({
    type: Types.UPDATE_FILTER_STICKINESS_CONDITION,
    shouldFilterBeSticky
});

const fetchTaxChargesData = (appId) => () => {
    return new Promise((resolve, reject) => {
        CatalogGatewayService.getTaxChargesData(appId).then((resp) => {
            resolve(resp.data);
        }).catch((error) => {
            reject(error);
        });
    });
};

const setBannerVisible = (bannerVisible) => (
    {type: Types.SET_BANNER_VISIBLE,
        bannerVisible}
);

export {
    fetchCarList,
    fetchOtherYardCarsList,
    updateSortOrder,
    resetCarList,
    setActiveScreen,
    setActiveTab,
    updateCurrentPage,
    updateSSRStatus,
    fetchCarCounts,
    updateIsRedirected,
    updateCarListOptions,
    setUserBannerStatus,
    setActiveBanner,
    setAppliedBannerFilter,
    clearAppliedBannerFilter,
    setCFormIcon,
    getBannerCarCount,
    setComingSoon,
    reloadCarList,
    couponClientMapping,
    getCouponForUser,
    getOptedCouponForUser,
    applyOptedCoupon,
    getBuyerTestimonial,
    updateBuyerTestimonialSSRStatus,
    setCarListingError,
    getNearestCities,
    setPAOfferFilter,
    getSimilarCarListing,
    updateSimilarCarSSR,
    updateOtherCitySSR,
    updateCarListForNonServiceable,
    shareLocationPopup,
    userHubLocation,
    updateListingPageVisited,
    changeLocationByNarby,
    updateFilterToolTipShowed,
    fetchSimilarCarsForListingCar,
    updateClickedCarIndex,
    fetchOtherCars,
    updateOtherCarsSSRStatus,
    updateTotalCarSize,
    updateOtherCarsListing,
    updateCmsData,
    fetchListingSeoData,
    fetchCmsSeoData,
    updateBestMatchesSnackBarViewStatus,
    transferRecommendations,
    updateMongoListeningConditions,
    updateFilterStickinessCondition,
    fetchTaxChargesData,
    setBannerVisible
};
